<template>

  <div class="record-detail page">
    <div class="record-detail__Zhezh" v-if="iSeject">
      <div class="record-detail__bg record-detail__bg1" :class="Unlock === true ? 'record-detail__bg1' : 'record-detail__bg2'">
        <img v-if="Unlock" src="../assets/image/butttttt.png" alt="" @click.stop="autoPlay">
        <img v-else src="../assets/image/buttttttttt(2).png" alt="" @click="iSeject = false" >
      </div>
    </div>
    <!--录播详情-->
    <route-back/>
    <div class="record-detail__tip">{{ courseInfo.courseName }}</div>
    <div class="record-detail__video" v-if="courseList.length>0">
      <div @click="hFirstPlay" class="hFirstPlay">
        <video loop="loop" :src="courseList[active1].topicUrl" poster="../assets/image/bg_videodL.png" controlslist="nodownload"
               :controls="play" ref="video"
               @pause="videoPause"
               @seeked="seeked "
               @play="videoPlay(active1)"
               :class="{'first': first}"></video>
        <!--        <img src="../assets/image/ic_play.png" alt="" class="play" v-if="first">-->
        <div>
          <span>解锁课程说明：为了激励同学们的学习热情，平台推出打卡解锁课程的玩法：即只要完成前一课次的学习，系统就会解锁下一课次哦~</span>
          <img src="../assets/image/pic_zhuangs.png" alt="">
        </div>
      </div>

      <!--      <div ref="player" id="player-con" v-else></div>-->
    </div>
    <div class="record-detail__menu">
      <div class="record-detail__menu-top">
        <div :class="{'active': active === 0}" @click="active = 0">
          课程介绍
        </div>
        <div :class="{'active': active === 1}" @click="active = 1">
          课程目录
        </div>
      </div>
      <div class="record-detail__menu-bottom hidden-scroll">
        <div v-if="active === 0" v-html="courseInfo.reamrk" class="record-detail__menu-bottom-intro">
        </div>
        <div v-if="active === 1">
          <div v-for="(i, k) in courseList" :key="k" class="record-detail__menu-bottom-item"
               @click="onCourseChange(i, k)" :class="{'active': active1===k}">
            <div>{{ k + 1 }}、{{ i.topicName }}</div>
            <!--0已解锁 1 未观看 2 未解锁-->
            <img v-if="i.unlockStatus === 2" src="../assets/image/ic_suo_close.png" alt="">
            <img v-if="i.unlockStatus === 1" src="../assets/image/ic_suo_open.png" alt="">
            <img v-if="i.unlockStatus === 0" src="../assets/image/class_v_green.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="40%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <div style="text-align: left;line-height: 20px;font-size: 16px">恭喜小朋友，学完了一节课程，现在请给出你对课程的评价，老师们一定会认真了解并采纳你们的建议的哦~
      </div>
      <div style="margin-top: 20px;height: 80px;display: flex;flex-direction: column;justify-content: space-between">
        <div style="text-align: left;display: flex;align-items: center;justify-content: space-between">
          <span>课程内容你喜欢吗</span>
          <el-rate v-model="lbkEvaluationBeanList[0].level"></el-rate>
        </div>
        <div style="text-align: left;display: flex;align-items: center;justify-content: space-between">
          <span>作品步骤讲解是否清晰</span>
          <el-rate v-model="lbkEvaluationBeanList[1].level"></el-rate>
        </div>
        <div style="text-align: left;display: flex;align-items: center;justify-content: space-between">
          <span>作品是否能很好完成</span>
          <el-rate v-model="lbkEvaluationBeanList[2].level"></el-rate>
        </div>
      </div>
      <div class="but" style="margin-top: 20px" @click="submitEvaluation">
        <img src="../assets/image/bt_login(1).png" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import RouteBack from "@/components/RouteBack";

export default {
  name: "RecordDetail",
  components: {RouteBack},
  data() {
    let reloadUrl;
    let LOCAL_URL = 'http://192.168.20.19:8081/#/video'
    let TEST_URL = 'http://39.99.242.100:1604/#/video'
    let PROD_URL = 'http://pccomm.xweiart.com/#/video'
    if (process.env.NODE_ENV === 'development') {
      reloadUrl = LOCAL_URL
    } else if (process.env.NODE_ENV === 'production') {
        if (process.env.VUE_APP_TITLE === 'test') {
          reloadUrl = TEST_URL
        } else if (process.env.VUE_APP_TITLE === 'build') {
          reloadUrl = PROD_URL
        }
      }
      return {
        lbkEvaluationBeanList:[
          {
            dimensionalityName:'课程内容你喜欢吗',
            level:0,
          },
          {
            dimensionalityName:'作品步骤讲解是否清晰',
            level:0,
          },
          {
            dimensionalityName:'作品是否能很好完成',
            level:0,
          },
        ],
        value1: 0,
        dialogVisible: false,
        active: 0,
        play: false,
        first: true,
        courseInfo: {},
        courseList: [],
        playProgressList: [],
        active1: 0,
        reloadUrl,
        isAliVideo: false,
        playTime: 0,//播放时间到百分之80解锁
        totalDuration: 0,//视频总时长
        eliminate: null,//清除定时器
        newTime: 0,//上报时长
        iSeject:false,
        Unlock:true,
        firstSetTime: true,
        once:0,
      }
    },
    created() {
      this.gCourse()
      this.gCourseList()
    },
    beforeDestroy() {
      this.Destruction()
      this.updateWatchTimes()
    },
    methods: {
      lbkEvaluationBeanListFUn(){
        var is = false
        for ( let i = 0; i < this.lbkEvaluationBeanList.length ; i++ ) {
          if ( this.lbkEvaluationBeanList[i].level === 0 ) {
            is = false
            break
          }else {
            is = true
          }
        }
        return is
       },
      //提交评价
      async submitEvaluation() {
        if ( this.lbkEvaluationBeanListFUn() ) {
          let res = await this.$_api('/online/adviser/dwsalecourse/lbkEvaluation','post',{
            topicUnlockInfoId: this.courseList[this.active1].topicUnlockInfoId,
            lbkEvaluationBeanList:this.lbkEvaluationBeanList,
          })
          if ( res ) {
            this.dialogVisible = false
          }
        }else {
          this.$message.error('请选择所有评价')
        }

      },
      async gCourseList() {
        const r = await this.$_api('/online/adviser/dwsalecourse/showAllTopic', 'post', {
          id: this.$route.query.courseId
        })
        this.courseList = r
        this.playProgressList = r.map(v => v.playProgress)
      },
      async gCourse() {
        this.courseInfo = await this.$_api('/online/adviser/dwsalecourse/showoneInfo', 'post', {
          id: this.$route.query.courseId
        })
      },
      async hFirstPlay() {
        this.first = false
        this.play = true
        // let cliObj = this.courseList[this.active1]
        // const _t = await this.$_api('/online/app/disposeVideo/getVideoToken', 'get')
        // let newUrl = this.reloadUrl + `?i=${cliObj.aesKey}&p=2&t=${_t}&n=${cliObj.topicName}&b=${null}`
        // window.WCRClassRoom.openUrlByExternal(newUrl)
        // window.open(newUrl)
      },
      onCourseChange(i, k) {
        this.once = 0
        //0已解锁 1 未观看 2 未解锁
        if ( i.unlockStatus === 1 ) {
          this.iSeject = true
          this.Unlock = true
        }
        if (i.unlockStatus === 0 || i.unlockStatus === 1) {
          this.active1 = k
          this.hTotalDuration()
          this.Destruction()
        }else {
          this.Unlock = false
          this.iSeject = true
        }
      },

      //当用户已经移动/跳跃到视频中新的位置时触发
      seeked(e){
        console.log('触发拖动')
        this.playProgressList[this.active1]  = Math.floor(e.target.currentTime)
      },

      //获取视频总时长
      hTotalDuration() {
        this.$nextTick(() => {
          let video = this.$refs['video']
          this.totalDuration = Math.floor(video.duration) //視頻縂時常
          this.topicId = this.courseList[this.active1].topicId //課題ID
        })
      },

      autoPlay(){
        this.iSeject = false
        let video = this.$refs['video']
        video.play()
        this.courseList[this.active1].unlockStatus = 0
      },

      //设置
      getWatchTimes() {
        this.hTotalDuration()
        this.eliminate = setInterval(() => {
          this.playTime++
          this.newTime++
        }, 1000)
      },

      videoPlay(index) {
        if (this.firstSetTime){
          let video = this.$refs['video']
          console.log(this.playProgressList)
          video.currentTime = this.playProgressList[index]
          this.firstSetTime = false
        }
        this.getWatchTimes()
      },

      //视频暂停
      videoPause() {
        clearInterval(this.eliminate)
        this.$nextTick(() => {
          this.updateWatchTimes()
        })
      },

      //置空
      Destruction() {
        clearInterval(this.eliminate)
        this.playTime = 0
      },

      //上报
      updateWatchTimes() {
        this.$nextTick(()=>{
          let video = this.$refs['video']
          this.$_api('/online/adviser/dwsalecourse/updateWatchTimesExE', 'post', {
            topicId: this.topicId,
            cumulativeTime: this.newTime,
            totalTime: this.totalDuration,
            nxTopicId: this.courseList[this.active1 + 1].topicId,
            playProgress: Math.floor(video.currentTime) ? Math.floor(video.currentTime) : 0
          })
          this.newTime = 0
          // this.gCourseList()
        })
      },
      //通知后台解锁
      async getApiupdateUnlockStatus() {
        let res = await this.$_api('/online/adviser/dwsalecourse/updateUnlockStatus', 'post', {
          topicId: this.courseList[this.active1 + 1].topicId,
          id: this.$route.query.courseId
        })
      },
      async isOkEvaluation(){
        let res = await this.$_api('/online/adviser/dwsalecourse/isOkEvaluation','post',{
           topicUnlockInfoId: this.courseList[this.active1].topicUnlockInfoId,
         })
        if ( res ) {
          this.dialogVisible = true
        }
      },
    },
    watch: {
      active1(){
        this.firstSetTime = true
      },
      playTime: {
        deep: true,
        handler(v) {
          if (v / this.totalDuration >= 0.8) {
            if (this.courseList[this.active1 + 1].unlockStatus !== 1 && this.courseList[this.active1 + 1].unlockStatus !== 0 ) {
              this.courseList[this.active1 + 1].unlockStatus = 1
              // this.getApiupdateUnlockStatus()
              this.updateWatchTimes()
            }
            if (this.once === 0) {
              this.isOkEvaluation()
              this.once = 1
            }
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .record-detail {
    position: relative;
    background: url("../assets/image/bg-zb.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    .record-detail__Zhezh {
      position: relative;
      background-color: rgba(0, 0, 0,0.6);
      width: 100%;
      height: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      .record-detail__bg1{
        background: url("../assets/image/bg_dianzan.png") no-repeat;
      }
      .record-detail__bg2{
        background: url("../assets/image/bg_dianzan (1).png") no-repeat;
      }
      .record-detail__bg{
        background-size: 100% 100%;
        width: 300px;
        height: 340px;
        overflow: hidden;
        position: relative;
        img{
          position: absolute;
          left: 50%;
          top:83%;
          transform: translate3d(-50%,-50%,0);
        }
      }
    }

    .record-detail__tip {
      width: 216px;
      height: 32px;
      padding: 22px 20px;
      position: absolute;
      top: 45px;
      text-align: center;
      left: 412px;
      background: url("../assets/image/bt_clsti.png") no-repeat;
      background-size: 100% 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 32px;
      color: #FFFFFF;
      font-size: 22px;
    }

    .record-detail__video {
      position: absolute;
      left: 100px;
      bottom: 138px;
      width: 471.43px;
      height: 265.18px;
      border-radius: 10px;

      & > :nth-child(1) {
        display: inline-block;
        position: relative;

        div {
          position: absolute;
          bottom: -77px;
          background-color: #8162C3;
          border-radius: 0px 0px 10px 10px;
          width: 100%;
          height: 80px;
          padding: 10px;
          box-sizing: border-box;
          text-align: left;

          span {
            font-size: 14px;
            color: #FFFFFF;
          }
        }

        /*img {*/
        /*  position: absolute;*/
        /*  top: calc(50% - 40px);*/
        /*  left: calc(50% - 40px);*/
        /*  cursor: pointer;*/
        /*}*/

        video {
          max-width: 100%;
          max-height: 100%;
        }

      }

    }

    .record-detail__menu {
      position: absolute;
      top: 177px;
      right: 100px;

      .record-detail__menu-top {
        text-align: center;

        div {
          cursor: pointer;
          transition: all .4s;
          display: inline-block;
          width: 158.93px;
          height: 47.1px;
          line-height: 47.1px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          color: #FFFFFF;
          border-radius: 30px 30px 0 0;
          background: #F2B583;

          &.active {
            background: #FC523A;
            font-weight: bold;
          }

          &:hover {
            opacity: .8;
          }
        }
      }

      .record-detail__menu-bottom {
        width: 345px;
        height: 329.88px;
        padding: 20px 15px;
        background: linear-gradient(44deg, #FF4B26, #EFA045);
        border-radius: 30px;
        overflow-y: auto;

        .record-detail__menu-bottom-intro {
          font-size: 16px;
          color: #FFFFFF;
          font-family: Microsoft YaHei;
          line-height: 30px;
        }

        .record-detail__menu-bottom-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            text-align: left;
            font-size: 16px;
            font-family: Microsoft YaHei;
            line-height: 21px;
            color: #FFFFFF;
          }

          &.active {
            color: #FEFE00;

            div {
              color: inherit;
            }
          }
        }

        .record-detail__menu-bottom-item + .record-detail__menu-bottom-item {
          margin-top: 10px;
        }
      }
    }
  }
</style>
