<template>
  <div class="route-back" @click="$router.back()">
    <div class="route-back__img"></div>
    <div class="route-back__text">返回</div>
  </div>
</template>

<script>
export default {
name: "RouteBack"
}
</script>

<style lang="scss" scoped>
  .route-back{
    height: 95px;
    width: 60px;
    position: absolute;
    top: 62px;
    left: 30px;
    cursor: pointer;
    .route-back__img{
      width: 60px;
      height: 60px;
      background: url("../assets/image/ic_back.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 50%;
    }
    .route-back__text{
      margin-top: 14px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      line-height: 21px;
      color: #FFFFFF;
    }
  }
</style>
